/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  border: 0px !important;
}

.modal-custom {
  --width: 50% !important;
  --border-radius: 5px
}

.modal-document {
  text-align: center;
  zoom: 0.8;
  box-sizing: border-box;
  --width: 1000px !important;
  --heigth: 700px !important;
  --border-radius: 5px
}
.modal-help {
  text-align: center;
  zoom: 0.8;
  box-sizing: border-box;
  --width: 500px !important;
  --heigth: 245px !important;
  --border-radius: 5px
}

ion-modal.create-expense-template-modal {
  --height: 750px !important;
}

ion-modal.create-expense-template-modal {
  --height: 750px !important;
}

ion-modal.modal-help {
  --height: 310px !important;
}
ion-modal.modal-document {
  --height: 800px !important;
}